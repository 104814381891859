import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Form, FloatingLabel, Button, Spinner, Image } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language-provider';
import { LoginManager } from '../../core/login-manager';
import loginBg from '../../assets/login.jpg';
import logo from '../../assets/logo.svg'


export default function Recover() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [sending, setSending] = useState(0)

    async function handleSubmit(e) {
        e.preventDefault();
        setSending(1)
        let response = await LoginManager.recoverPassword(email)
        setSending(0)

        if (response.success === 1) {
            navigate('/reset')
        }
    }

    return (
        <Container fluid id="login-container" >
            <div className="text-center" style={{ width: '400px' }}>
                <Form onSubmit={handleSubmit} >
                    <Image src={logo} className='mb-5'></Image>
                    <hr></hr>
                    <div className="mb-4 mt-5">
                        <h5>{LanguageProvider.get('login.forgot')}</h5>
                    </div>

                    <FloatingLabel label='Email' className="mb-3">
                        <Form.Control type="text" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} required/>
                        
                    </FloatingLabel>

                    {sending === 1 ? (
                        <Button variant="primary" size="lg" disabled>
                            {LanguageProvider.get('login.send_forgot_password_key')}
                            <Spinner size="sm" className='ms-3' />
                        </Button>
                    ) : (
                        <Button variant="danger" className='text-white' style={{ width: '100%' }} type="submit">Invia</Button>
                    )}
                </Form>
                <div className="text-center mt-2">
                    <Link to="/" className='text-black'>{LanguageProvider.get('login.back_to_login')}</Link>
                </div>
            </div>
        </Container>
    );
};