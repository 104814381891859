import avatar1 from '../assets/avatar/1.svg';
import avatar2 from '../assets/avatar/2.svg';
import avatar3 from '../assets/avatar/3.svg';
import avatar4 from '../assets/avatar/4.svg';
import avatar5 from '../assets/avatar/5.svg';
import avatar6 from '../assets/avatar/6.svg';
import avatar7 from '../assets/avatar/7.svg';
import avatar8 from '../assets/avatar/8.svg';
import avatar9 from '../assets/avatar/9.svg';
import avatar10 from '../assets/avatar/10.svg';
import avatar11 from '../assets/avatar/11.svg';
import avatar12 from '../assets/avatar/12.svg';
import avatar13 from '../assets/avatar/13.svg';
import avatar14 from '../assets/avatar/14.svg';
import avatar15 from '../assets/avatar/15.svg';
import avatar16 from '../assets/avatar/16.svg';
import avatar17 from '../assets/avatar/17.svg';
import avatar18 from '../assets/avatar/18.svg';

export default [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
    avatar13,
    avatar14,
    avatar15,
    avatar16,
    avatar17,
    avatar18
];