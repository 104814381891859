import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import { ApiManager } from '../../core/api-manager';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { toast } from 'react-toastify';
import logo from '../../assets/logo.svg'


export default function RegistrazioneClienti() {

    const navigate = useNavigate();
    const [dataAgente, setDataAgente] = useState(null);


    const [validated, setValidated] = useState(false);

    const [codice, setCodice] = useState(null);
    const [ragioneSociale, setRagioneSociale] = useState(null);
    const [indirizzo, setIndirizzo] = useState(null);
    const [cap, setCap] = useState(null);
    const [citta, setCitta] = useState(null);
    const [provincia, setProvincia] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefono, setTelefono] = useState(null);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };

    useEffect(() => {
        getAgent();
    }, []);

    async function getAgent() {
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var response = await DataManager.getItems('agent', 1, 100, sorting);
        console.warn(response);
        /* if(response.success === 1){
            setDataAgente(response.body);
        } */
    }



    return (
        <>
            <Container fluid='md' className='mt-5 mb-5 '>
                <Row>
                    <Col md="4"></Col>
                    <Col md="4" className='align-items-center'>
                        <h1>Registrazione clienti</h1>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Select aria-label="Default select example" required>
                                <option>Scegli l'agente</option>
                            </Form.Select>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Codice</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Codice"
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom02">
                                <Form.Label>Ragione sociale</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Ragione sociale"
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Indirizzo</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Indirizzo"
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>CAP</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="CAP"
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Città</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Città"
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Provincia</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Provincia"
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Email"
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Telefono"
                                />
                            </Form.Group>
                            <Button variant='danger' className='mt-2 text-white w-100' type="submit">Crea cliente</Button>
                        </Form>
                    </Col>
                    <Col md="4"></Col>
                </Row>
            </Container>
        </>
    )


};