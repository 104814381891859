export const settings = {
    "title": "Annamaria B2B",
    "apiUrl": "https://api.annamariagroup.com/api",
    "ckdPath": "https://api.annamariagroup.com/",
    "config": {
        "limit": 100,
        "sortingField": 'id',
        "sortingOrder": 'desc'
    },
    "mail": 'b2b@annamariagroup.com'
}