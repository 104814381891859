import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb, Form, Tab, Tabs, Table, Modal, Accordion, FormLabel, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import { LoginManager } from '../../core/login-manager';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../components/Breadcrumbs';


export default function Profile() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { p } = useParams();
    const page = parseInt(p);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showEditInfo, setShowEditInfo] = useState(false);
    const handleCloseEditInfo = () => setShowEditInfo(false);
    const handleShowEditInfo = () => setShowEditInfo(true);

    const [showEditDati, setShowEditDati] = useState(false);
    const handleCloseEditDati = () => setShowEditDati(false);
    const handleShowEditDati = () => setShowEditDati(true);

    const [showEditInd, setShowEditInd] = useState(false);
    const handleCloseEditInd = () => setShowEditInd(false);
    const handleShowEditInd = () => setShowEditInd(true);

    const [data, setData] = useState([]);
    const [dataOrder, setDataOrder] = useState([]);
    const [dataOrderAgent, setDataOrderAgent] = useState([]);
    const [detailOrder, setDetailOrder] = useState([]);
    const [rowsOrder, setRowsOrder] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [userInfo, setUserInfo] = useState();

    const [agentId, setAgentId] = useState();

    const [name, setName] = useState('Nome');
    const [level, setLevel] = useState(null);
    const [idCustomer, setId] = useState(0);
    const [idFatturazione, setIdFatturazione] = useState(0);
    const [idSpedizione, setIdSpedizione] = useState(0);
    const [surname, setSurname] = useState('Cognome');
    const [username, setUsername] = useState('Username');
    const [email, setEmail] = useState('Email');
    const [ragsoc, setRagSoc] = useState('Ragione sociale');
    const [iva, setIva] = useState('Partita IVA');
    const [sdi, setSdi] = useState('Codice SDI');
    const [iSpedizione, setSpedizione] = useState('Indirizzo spedizione');
    const [iFatturazione, setFatturazione] = useState('Indirizzo fatturazione');

    const [viaS, setViaS] = useState('');
    const [cittaS, setCittaS] = useState('');
    const [capS, setCapS] = useState('');
    const [provinciaS, setProvinciaS] = useState('');

    const [viaF, setViaF] = useState('');
    const [cittaF, setCittaF] = useState('');
    const [capF, setCapF] = useState('');
    const [provinciaF, setProvinciaF] = useState('');


    const [showLogout, setShowLogout] = useState(false);

    const handleCloseLogout = () => setShowLogout(false);
    const handleShowLogout = () => setShowLogout(true);

    const [customers, setCustomers] = useState([]);



    useEffect(() => {
        getUserInfo();

    }, []);

    useEffect(() => {
        getAzienda();
        getAgentCustomer();
        getOrderAgent();
    }, [userInfo]);

    useEffect(() => {
        getOrder();

    }, [idCustomer]);


    useEffect(() => {
        getOrderAgent();
    }, [agentId]);



    function getUserInfo() {
        var u = LoginManager.getUserInfo()
        setUserInfo(u);
    }

    async function getOrder() {
        var constraints = [
            {
                "fieldName": "idCustomer",
                "type": "value",
                "value": idCustomer
            }
        ]
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var response = await DataManager.searchItems('customer_order', page, 30, sorting, '', [], constraints)
        if (response.success === 1) {
            setDataOrder(response.body)
            console.warn(response.body)
        }

    }

    async function doLogout() {
        var response = await LoginManager.logout();
        navigate('/')
    }

    async function getAzienda() {

        if (userInfo.level === 2) {
            var constraints = [
                {
                    "fieldName": "id",
                    "type": "value",
                    "value": userInfo.id
                }
            ]
            var sorting = {
                'field': 'id',
                'order': 'desc'
            }
            var response = await DataManager.searchItems('user', 1, 30, sorting, '', [], constraints);
            if (response.success === 1) {
                setName(response.body[0].name);
                setSurname(response.body[0].surname);
                setUsername(response.body[0].username);
                setEmail(response.body[0].email);
                setLevel(response.body[0].level);
            }

        } else {

            var constraints = [
                {
                    "fieldName": "idUser",
                    "type": "value",
                    "value": userInfo.id
                }
            ]
            var sorting = {
                'field': 'id',
                'order': 'desc'
            }
            var response = await DataManager.searchItems('customer', 1, 30, sorting, '', [], constraints);
            if (response.success === 1) {
                setId(response.body[0].id);
                setData(response.body);
                setName(response.body[0].idUser.name);
                setLevel(response.body[0].idUser.level);
                setSurname(response.body[0].idUser.surname);
                setUsername(response.body[0].idUser.username);
                setEmail(response.body[0].idUser.email);
                setRagSoc(response.body[0].ragione_sociale);
                setIva(response.body[0].vat_number);
                setSdi(response.body[0].sdi);
                setFatturazione(response.body[0].idBillingAddress[0].address + ', ' + response.body[0].idBillingAddress[0].cap + ' ' + response.body[0].idBillingAddress[0].city + ', ' + response.body[0].idBillingAddress[0].province);
                setSpedizione(response.body[0].idShippingAddress[0].address + ', ' + response.body[0].idShippingAddress[0].cap + ' ' + response.body[0].idShippingAddress[0].city + ', ' + response.body[0].idShippingAddress[0].province);
                setIdSpedizione(response.body[0].idShippingAddress.id);
                setIdFatturazione(response.body[0].idBillingAddress.id);
                setViaS(response.body[0].idShippingAddress.address);
                setCapS(response.body[0].idShippingAddress.cap);
                setCittaS(response.body[0].idShippingAddress.city);
                setProvinciaS(response.body[0].idShippingAddress.province);
                setViaF(response.body[0].idBillingAddress.address);
                setCapF(response.body[0].idBillingAddress.cap);
                setCittaF(response.body[0].idBillingAddress.city);
                setProvinciaF(response.body[0].idBillingAddress.province);
            }
        }

    }

    async function getAgentCustomer() {

        var sorting = {
            'field': 'ragione_sociale',
            'order': 'asc'
        }
        var constraints = [
            {
                "fieldName": "idAgent",
                "type": "value",
                "value": userInfo.id
            }
        ]
        var response1 = await DataManager.searchItems('customer', 1, 100, sorting, '', [], constraints);
        setCustomers(response1.body);


    }

    async function getOrderAgent() {
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var constraints = [
            {
                "fieldName": "idAgent",
                "type": "value",
                "value": userInfo.id
            }
        ]
        var responseOrder = await DataManager.searchItems('gyv_orderAgent', 1, 100, sorting, '', [], constraints);
        if (responseOrder.success === 1) {
            setDataOrderAgent(responseOrder.body);
            console.warn('order', responseOrder.body)
        }
    }

    async function customerOrder(id) {
        var constraints = [
            {
                "fieldName": "id",
                "type": "value",
                "value": id
            }
        ]
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var response = await DataManager.searchItems('customer_order', page, 30, sorting, '', [], constraints)
        console.warn(response.body[0].rows)
        if (response.success === 1) {
            setDetailOrder(response.body[0]);
            setRowsOrder(response.body[0].rows)
            handleShow();
        }

    }

    async function editProfile() {
        const data = {
            'name': name,
            'surname': surname,
            'username': username,
            'email': email
        }
        const dataAzienda = {
            'ragione_sociale': ragsoc,
            'vat_number': iva,
            'sdi': sdi
        }
        const dataSpedizione = {
            'province': provinciaS,
            'address': viaS,
            'cap': capS,
            'city': cittaS
        }

        const dataFatturazione = {
            'province': provinciaF,
            'address': viaF,
            'cap': capF,
            'city': cittaF,
        }

        //console.log(idFatturazione, dataFatturazione);
        //console.log(idSpedizione, dataSpedizione);
        var response = await DataManager.updateItem('user', data, userInfo.id);
        var responseAzienda = await DataManager.updateItem('customer', dataAzienda, idCustomer);
        var responseSpedizione = await DataManager.updateItem('shipping_address', dataSpedizione, idSpedizione);
        var responseFatturazione = await DataManager.updateItem('billing_address', dataFatturazione, idFatturazione);

        if (response.success === 1 && responseAzienda.success === 1 && responseFatturazione.success === 1 && responseSpedizione.success === 1) {
            toast.success('Campi salvati con successo!', { theme: 'colored' });
            handleCloseEditInd();
            handleCloseEditInfo();
            handleCloseEditDati();
            getAzienda();
        } else {
            toast.error('Errore nel salvataggio dei dati!', { theme: 'colored' });
            handleCloseEditInd();
            handleCloseEditInfo();
            handleCloseEditDati();

        }


    }




    return (
        <>

            <Container className='d-flex align-items-center mt-3 mb-5'>
                <Breadcrumbs title='Area personale'></Breadcrumbs>

            </Container>
            <Container fluid='md' className='mt-5 mb-5'>
                <Row>
                    <Col md="2"></Col>
                    <Col md="8">
                        <Tabs
                            defaultActiveKey="info"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            fill
                            justify
                            variant='tabs'
                        >
                            <Tab eventKey="info" title="Info personali">
                                <Container>
                                    <Col className='mt-5'>
                                        <Table bordered striped>

                                            <tbody>
                                                <tr>
                                                    <td>Nome</td>
                                                    <td>{name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cognome</td>
                                                    <td>{surname}</td>
                                                </tr>
                                                <tr>
                                                    <td>Username</td>
                                                    <td>{username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>{email}</td>
                                                </tr>
                                                {level && level.value === 0 ? <tr>
                                                    <td>Tipologia account</td>
                                                    <td>{level.value === 0 ? <Badge bg='primary'>Amministratore</Badge> : <></>}</td>
                                                </tr> : null}
                                                {level && level.value === 1 ? <tr>
                                                    <td>Tipologia account</td>
                                                    <td>{level.value === 1 ? <Badge bg='danger'>Cliente</Badge> : <></>}</td>
                                                </tr> : null}
                                                {level && level.value === 2 ? <tr>
                                                    <td>Tipologia account</td>
                                                    <td>{level.value === 2 ? <Badge bg='success'>Agente</Badge> : <></>}</td>
                                                </tr> : null}

                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Button variant='danger' style={{ color: 'white' }} onClick={handleShowEditInfo}>Modifica dati</Button>
                                </Container>
                            </Tab>
                            {userInfo && userInfo.level !== 2 ?
                                <Tab eventKey="azienda" title="Dati aziendali" >
                                    <Container>
                                        <Col className='mt-5'>
                                            <Table bordered striped>

                                                <tbody>
                                                    <tr>
                                                        <td>Ragione sociale</td>
                                                        <td>{ragsoc}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Partita IVA</td>
                                                        <td>{iva}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Codice SDI</td>
                                                        <td>{sdi}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Button variant='danger' style={{ color: 'white' }} onClick={handleShowEditDati}>Modifica dati</Button>
                                    </Container>
                                </Tab> : null}

                            {userInfo && userInfo.level === 2 ?
                                <Tab eventKey="clienti" title="Clienti" >
                                    <Container>
                                        <Col className='mt-5'>
                                            <Table bordered striped>

                                                <thead>
                                                    <tr>
                                                        <th>Ragione sociale</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {customers.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.ragione_sociale}</td>
                                                            </tr>
                                                        )

                                                    })}

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Container>
                                </Tab> : null}


                            {userInfo && userInfo.level !== 2 ?
                                <Tab eventKey="indirizzi" title="Indirizzi">
                                    <Container>
                                        <Col className='mt-5'>
                                            <Table bordered striped>

                                                <tbody>
                                                    <tr>
                                                        <td>Indirizzo di spedizione</td>
                                                        <td>{iSpedizione}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Indirizzo di fatturazione</td>
                                                        <td>{iFatturazione}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Button variant='danger' style={{ color: 'white' }} onClick={handleShowEditInd}>Modifica dati</Button>
                                    </Container>
                                </Tab> : null}

                            {userInfo && userInfo.level !== 2 ?
                                <Tab eventKey="ordini" title="Ordini">
                                    <Container>
                                        <Col className='mt-5'>
                                            {dataOrder && dataOrder.length > 0 ?
                                                <Table bordered striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Data e ora</th>
                                                            <th>Indirizzo di spedizione</th>
                                                            <th>Importo</th>
                                                            <th>Partita IVA</th>
                                                            <th>Dettagli</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataOrder.map((item, index) => (

                                                            <tr key={item.id}>
                                                                <td>{item.date}</td>
                                                                <td>{item.shipping_address}</td>
                                                                <td>{item.total_price} €</td>
                                                                <td>{item.vat_number}</td>
                                                                <td><Button variant='danger' style={{ color: 'white' }} onClick={() => customerOrder(item.id)}>Vedi</Button></td>


                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </Table> :
                                                <h4>Nessun ordine ancora effettuato</h4>}


                                        </Col>
                                    </Container>
                                </Tab> :
                                <Tab eventKey="ordiniAgent" title="Ordini">
                                    <Container>
                                        <Col className='mt-5'>
                                            {dataOrderAgent && dataOrderAgent.length > 0 ?
                                                <Table bordered striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Data e ora</th>
                                                            <th>Cliente</th>
                                                            <th>Indirizzo di spedizione</th>
                                                            <th>Importo</th>
                                                            <th>Partita IVA</th>
                                                            <th>Dettagli</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataOrderAgent.map((item, index) => (

                                                            <tr key={item.id}>
                                                                <td>{item.date}</td>
                                                                <td>{item.idCustomer.ragione_sociale}</td>
                                                                <td>{item.shipping_address}</td>
                                                                <td>{item.total_price} €</td>
                                                                <td>{item.vat_number}</td>
                                                                <td><Button variant='danger' style={{ color: 'white' }} onClick={() => customerOrder(item.id)}>Vedi</Button></td>


                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </Table> :
                                                <h4>Nessun ordine ancora effettuato</h4>}


                                        </Col>
                                    </Container>
                                </Tab>}


                            <Tab eventKey="logout" title="Logout">
                                <Container>
                                    <Col className='mt-5'>
                                        <Button variant='danger' style={{ color: 'white' }} onClick={handleShowLogout}>Logout</Button>
                                    </Col>
                                </Container>
                            </Tab>
                        </Tabs>

                    </Col>
                    <Col md="2"></Col>
                </Row>


            </Container>

            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{detailOrder.date}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailOrder.idCustomer && detailOrder.idCustomer.ragione_sociale ? <p>Cliente: <b>{detailOrder.idCustomer.ragione_sociale}</b></p> : null}

                    <p>Indirizzo di spedizione: <b>{detailOrder.shipping_address}</b></p>
                    <p>Indirizzo di fatturazione: <b>{detailOrder.billing_address}</b></p>
                    {rowsOrder && rowsOrder.length > 0 ?
                        <Table bordered striped>
                            <thead>
                                <tr>
                                    <th>Codice</th>
                                    <th>Quantità</th>
                                    <th>Prezzo di listino</th>
                                    <th>Prezzo totale</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowsOrder.map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.code}</td>
                                            <td>{item.quantity}</td>
                                            <td>{parseFloat(item.listPrice).toFixed(2)} €</td>
                                            <td>{parseFloat(item.price).toFixed(2)} €</td>
                                        </tr>
                                    )

                                })}




                            </tbody>
                        </Table>

                        : null}

                    <p>Subtotale: <b>{detailOrder.subtotal}€</b></p>
                    <p>Costi di spedizione: <b>{detailOrder.shipping_price}€</b></p>
                    <p>IVA: <b>{parseInt(detailOrder.tax_rate)}%</b></p>
                    <p>Totale IVA: <b>{detailOrder.tax_amount}€</b></p>
                    <p>Totale ordine: <b>{detailOrder.total_price_iva}€</b></p>


                </Modal.Body>

            </Modal>
            <Modal show={showLogout} onHide={handleCloseLogout}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sei sicuro di voler effettuare il logout?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLogout}>
                        No, chiudi
                    </Button>
                    <Button variant="danger" style={{ color: 'white' }} onClick={doLogout}>
                        Si, sono sicuro
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditInfo} onHide={handleCloseEditInfo}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifica informazioni</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-4" controlId="formNome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" placeholder='Nome' value={name} onChange={e => { setName(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formCognome">
                            <Form.Label>Cognome</Form.Label>
                            <Form.Control type="text" placeholder='Cognome' value={surname} onChange={e => { setSurname(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder='Username' value={username} onChange={e => { setUsername(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder='Email' value={email} onChange={e => { setEmail(e.target.value) }} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditInfo}>
                        Indietro
                    </Button>
                    <Button variant="danger" style={{ color: 'white' }} onClick={editProfile}>
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showEditDati} onHide={handleCloseEditDati}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifica dati</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-4" controlId="formRagSoc">
                            <Form.Label>Ragione sociale</Form.Label>
                            <Form.Control type="text" placeholder='Ragione sociale' value={ragsoc} onChange={e => { setRagSoc(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formIva">
                            <Form.Label>Partita IVA</Form.Label>
                            <Form.Control type="text" placeholder='Partita IVA' value={iva} onChange={e => { setIva(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formSDI">
                            <Form.Label>Codice SDI</Form.Label>
                            <Form.Control type="text" placeholder='Codice SDI' value={sdi} onChange={e => { setSdi(e.target.value) }} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditDati}>
                        Indietro
                    </Button>
                    <Button variant="danger" style={{ color: 'white' }} onClick={editProfile}>
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showEditInd} onHide={handleCloseEditInd}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifica indirizzi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormLabel>Indirizzo di spedizione</FormLabel>
                        <Form.Group className="mb-3" controlId="formVia">
                            <Form.Control type="text" placeholder="Via" value={viaS} onChange={e => setViaS(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCap">
                            <Form.Control type="text" placeholder="CAP" value={capS} onChange={e => setCapS(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCitta">
                            <Form.Control type="email" placeholder="Città" value={cittaS} onChange={e => setCittaS(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formProvincia">
                            <Form.Control type="text" placeholder="Provincia" value={provinciaS} onChange={e => setProvinciaS(e.target.value)} />
                        </Form.Group>
                        <FormLabel>Indirizzo di fatturazione</FormLabel>
                        <Form.Group className="mb-3" controlId="formVia">
                            <Form.Control type="text" placeholder="Via" value={viaF} onChange={e => setViaF(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCap">
                            <Form.Control type="text" placeholder="CAP" value={capF} onChange={e => setCapF(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCitta">
                            <Form.Control type="email" placeholder="Città" value={cittaF} onChange={e => setCittaF(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formProvincia">
                            <Form.Control type="text" placeholder="Provincia" value={provinciaF} onChange={e => setProvinciaF(e.target.value)} />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditInd}>
                        Indietro
                    </Button>
                    <Button variant="danger" style={{ color: 'white' }} onClick={editProfile}>
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )


};