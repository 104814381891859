import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Form, FloatingLabel, Button, Alert, Row, Col, Image } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language-provider';
import { LoginManager } from '../../core/login-manager';
import loginBg from '../../assets/login.jpg';
import logo from '../../assets/logo.svg';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';


export default function Login() {

    const navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');


    async function handleSubmit(e) {
        e.preventDefault();

        var response = await LoginManager.login(username, password, 1);
        console.log(response)
        if (response.success === 0) {
            setErrors(response.message)
        } else {
            navigate('/');
        }
    }
    function registrati() {
        navigate('/registrati');
    }

    return (
        <>

            <Container style={{height: '100vh'}}>
                <Row style={{height: '100%'}}>
                    <Col md="4"></Col>
                    <Col md="4" className="d-flex flex-column justify-content-center align-items-center">
                        <div className='d-flex flex-row justify-content-center w-100 pb-5 mb-5 border-bottom border-secondary'>
                            <Image src={logo} className='d-none d-md-block' style={{width: '500px'}}></Image>
                            <Image src={logo} className='d-block d-md-none' style={{width: '250px'}}></Image>
                        </div>
                        <h4 className="mb-4 text-center">Benvenuto nell'area clienti B2B</h4>
                        <h3 className="mb-4 text-center text-uppercase">{LanguageProvider.get('forms.accedi')}</h3>
                        <p className='text-center'>{LanguageProvider.get('forms.accedi_subtitle')}</p>
                        <Container className='mb-3'>
                            <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Control type="email" placeholder="Indirizzo email" onChange={e => setUserName(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                            <div className="mb-3 mt-2">
                                <Form.Check type="checkbox" label={LanguageProvider.get('login.remember')} />
                            </div>
                            <Button variant="danger" style={{ color: 'white' }} type="submit" className='w-100'>
                                {LanguageProvider.get('buttons.accedi')}
                            </Button>
                            <div className="text-center mt-1">
                                <Link to="/recover" style={{ color: 'black' }}>{LanguageProvider.get('login.forgot')}</Link>
                            </div>
                        </Form>
                        </Container>
                        
                        {errors.length > 0 &&
                            <Alert variant='danger'>
                                {errors}
                            </Alert>
                        }
                        <Container className='text-center mt-5 pt-5 border-top border-secondary'>
                            <p className='text-center'>{LanguageProvider.get('forms.accedi_footer')}</p>
                            <Button variant="danger" style={{ color: 'white' }} onClick={registrati}>
                                {LanguageProvider.get('buttons.registrati')}
                            </Button>
                        </Container>
                    </Col>
                    <Col md="4"></Col>
                </Row>
            </Container>
        </>
    );
};