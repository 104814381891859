import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import { ApiManager } from '../../core/api-manager';


export default function ConfermaOrdine() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { p } = useParams();
    const page = parseInt(p)

    const [data, setData] = useState(null);
    const [totalPages, setTotalPages] = useState(0);

    const [name, setUserName] = useState('');
    const [surname, setUserSurname] = useState('');
    const [email, setUserEmail] = useState('');
    const [phone, setUserPhone] = useState('');
    const [password, setUserPassword] = useState('');


    useEffect(() => {
    }, [page]);





    return (
        <>
            <Container fluid='md' className='mb-5' style={{marginTop: '100px'}}>
                <div className='d-flex flex-row justify-content-center'>

                    <svg

                        width="200"
                        height="210"
                        viewBox="0 0 276 280"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.41738 277.501C11.6989 283.225 33.4532 273.243 50.624 265.24C63.4905 259.26 114.98 238.134 140.573 227.175C147.483 224.221 157.534 220.358 164.77 210.842C171.192 202.373 188.223 166.403 153.928 129.967C119.121 92.9734 83.2671 103.187 69.8655 112.68C61.9781 118.264 55.5565 130.852 52.6715 137.25C40.4797 164.286 23.0996 213.82 16.0265 233.295C10.838 247.65 1.18238 271.824 6.41738 277.501Z"
                            fill="#FFC107"
                        />
                        <path
                            d="M49.2255 145.393C49.5512 149.441 50.3655 156.026 53.1575 168.706C55.0654 177.408 58.1831 186.528 60.7192 192.555C68.3274 210.679 79.0068 217.985 89.8258 223.848C108.206 233.806 120.701 235.668 120.701 235.668L105.717 241.787C105.717 241.787 96.6429 239.902 84.265 233.806C72.4688 227.99 60.1841 218.148 51.2031 198.883C47.3176 190.53 45.0607 182.434 43.7578 176.012C42.1524 168.055 41.8965 163.541 41.8965 163.541L49.2255 145.393Z"
                            fill="#FF8F00"
                        />
                        <path
                            d="M30.8227 192.578C30.8227 192.578 32.684 207.678 45.155 226.734C59.7664 249.023 80.1713 252.676 80.1713 252.676L66.6068 258.26C66.6068 258.26 51.4602 253.63 37.0582 233.969C28.0773 221.708 25.5645 207.05 25.5645 207.05L30.8227 192.578Z"
                            fill="#FF8F00"
                        />
                        <path
                            d="M17.9095 228.362C17.9095 228.362 21.3064 241.391 28.7517 251.117C37.6163 262.727 48.9006 266.1 48.9006 266.1L38.5004 270.707C38.5004 270.707 30.613 269.055 21.9811 258.12C15.4199 249.814 13.5586 240.274 13.5586 240.274L17.9095 228.362Z"
                            fill="#FF8F00"
                        />
                        <path
                            opacity="0.44"
                            d="M12.2563 261.447C11.791 260.4 11.791 259.214 12.2796 258.19L71.5397 135.295L81.2884 171.94L18.9338 262.006C17.2586 264.518 13.4894 264.193 12.2563 261.447Z"
                            fill="#FFFDE7"
                        />
                        <path
                            d="M85.9892 184.249C113.677 216.636 145.203 212.587 155.696 204.398C166.213 196.185 174.519 167.962 146.948 136.11C118.051 102.746 85.3378 112.262 77.7761 119.474C70.2144 126.687 60.5821 154.537 85.9892 184.249Z"
                            fill="url(#paint0_linear_1215_2928)"
                        />
                        <path
                            d="M181.08 197.581C170.982 189.111 165.608 190.624 158.395 193.625C149.088 197.487 134.454 200.349 114.584 193.625L120.564 179.223C132.36 183.202 140.899 181.271 148.274 176.92C157.767 171.336 170.75 163.681 190.945 180.642C199.368 187.715 208 192.415 214.328 190.275C218.935 188.739 221.378 181.876 222.611 176.408C222.727 175.919 222.913 174.523 223.053 173.29C224.17 164.751 226.031 146.324 239.759 136.901C254.44 126.827 269.866 126.827 269.866 126.827L272.658 154.561C265.561 153.514 260.629 154.956 256.464 157.259C240.782 165.984 254.44 199.488 230.033 210.749C206.557 221.661 187.362 202.839 181.08 197.581Z"
                            fill="#03A9F4"
                        />
                        <path
                            d="M94.7129 162.215L84.6152 153.164C103.159 132.457 98.2727 117.241 94.7129 106.166C93.9917 103.932 93.3169 101.815 92.8749 99.7906C91.2927 92.6245 90.967 86.389 91.4556 80.9446C84.336 72.0801 81.195 62.7967 80.9856 62.1685C76.658 49.0693 79.9153 36.296 87.3839 24.3136C102.484 0 129.822 0 129.822 0L138.943 24.4067C132.009 24.1275 109.278 24.4765 102.298 35.4816C93.4798 49.3485 99.2732 57.9107 99.692 58.8879C101.39 56.6775 103.112 54.9093 104.648 53.5365C115.792 43.6482 125.471 42.229 131.637 42.7874C138.571 43.4156 144.853 46.9056 149.343 52.6291C154.252 58.9111 156.276 67.0777 154.718 74.4998C153.205 81.7357 148.389 87.8548 141.153 91.7404C128.519 98.5342 118.003 97.6036 110.953 95.2536C111 95.4165 111.023 95.6026 111.069 95.7655C111.325 96.9288 111.837 98.5575 112.442 100.442C116.56 113.192 124.215 133.434 94.7129 162.215ZM111.814 74.8953C113.163 75.8725 114.583 76.6869 116.048 77.2685C120.934 79.2229 126.262 78.5714 132.312 75.3141C135.872 73.4062 136.29 71.3588 136.43 70.6841C136.849 68.6599 136.151 66.0773 134.638 64.1461C133.312 62.4477 131.777 61.5635 129.939 61.3774C126.449 61.0749 121.725 63.2853 117.002 67.4965C114.746 69.5207 113.024 72.0103 111.814 74.8953Z"
                            fill="#F44336"
                        />
                        <path
                            d="M135.126 166.008L120.678 165.612C120.678 165.612 127.541 126.85 149.761 120.335C153.926 119.125 158.486 117.892 163.07 117.217C165.792 116.799 170.096 116.17 172.213 115.379C172.702 111.727 171.166 107.073 169.445 101.792C168.095 97.6968 166.699 93.4855 165.955 88.8787C164.512 79.8978 166.909 71.9639 172.702 66.4962C179.775 59.8652 191.199 57.748 204.089 60.6796C211.441 62.3548 216.862 65.9611 221.632 69.1253C228.449 73.6623 232.427 75.9657 240.757 70.3585C250.831 63.5646 237.662 36.9708 230.659 21.6149L256.788 10.7261C260.301 18.4041 277.262 57.9108 266.071 80.4562C262.302 88.0411 255.81 93.0667 247.295 94.9513C228.775 99.0928 217.932 91.8801 210.022 86.6219C206.276 84.1323 202.995 82.1779 199.435 81.1542C174.703 74.1044 209.231 110.493 193.06 126.85C183.358 136.645 159.649 139.228 158.114 139.6C142.851 143.276 135.126 166.008 135.126 166.008Z"
                            fill="#F48FB1"
                        />
                        <path
                            d="M91.4314 80.9448C90.9893 86.0635 90.7799 89.1114 92.1061 95.7657C98.5044 100.466 112.441 100.466 112.441 100.466C111.836 98.5809 111.301 96.9523 111.068 95.7889C111.022 95.6261 110.999 95.4399 110.952 95.2771C96.7827 88.204 91.4314 80.9448 91.4314 80.9448Z"
                            fill="#C92B27"
                        />
                        <path
                            d="M62.4425 103.862L38.3848 92.0658L50.3671 74.7554L69.2363 87.2496L62.4425 103.862Z"
                            fill="#FFC107"
                        />
                        <path
                            d="M26.9836 71.196C14.6988 69.5441 2.18133 59.1206 0.808594 57.934L12.884 43.7646C16.5368 46.8591 24.2846 52.0476 29.4731 52.7456L26.9836 71.196Z"
                            fill="#FB8C00"
                        />
                        <path
                            d="M48.669 40.1816L30.9863 34.3882C33.0105 28.1993 33.5457 21.5218 32.4987 15.0769L50.8793 12.1221C52.3916 21.4753 51.6238 31.1775 48.669 40.1816Z"
                            fill="#03A9F4"
                        />
                        <path
                            d="M159.1 26.3491L177.283 22.3715L182.533 46.3735L164.35 50.3511L159.1 26.3491Z"
                            fill="#FB8C00"
                        />
                        <path
                            d="M204.205 32.0385L191.408 18.5206C198.109 12.1688 199.645 3.8626 199.645 3.76953L218.025 6.77093C217.793 8.23673 215.443 21.4056 204.205 32.0385Z"
                            fill="#FFC107"
                        />
                        <path
                            d="M211.316 103.726L227.573 98.6452L233.125 116.412L216.869 121.492L211.316 103.726Z"
                            fill="#FB8C00"
                        />
                        <path
                            d="M216.051 253.676L197.554 251.489C198.345 244.905 193.435 236.831 192.086 235.04L206.977 223.872C208.093 225.337 217.796 238.762 216.051 253.676Z"
                            fill="#F44336"
                        />
                        <path
                            d="M269.143 230.084C262.186 229.037 255.067 228.618 248.04 228.874L247.412 210.261C255.579 209.982 263.838 210.447 271.912 211.68L269.143 230.084Z"
                            fill="#FB8C00"
                        />
                        <path
                            d="M244.117 255.705L257.195 242.459L275.192 260.229L262.114 273.474L244.117 255.705Z"
                            fill="#F48FB1"
                        />
                        <path
                            d="M205.691 138.043L219.139 153.42L203.763 166.868L190.315 151.491L205.691 138.043Z"
                            fill="#F44336"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_1215_2928"
                                x1="162.151"
                                y1="134.571"
                                x2="92.8924"
                                y2="176.126"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset="0.0235" stop-color="#8F4700" />
                                <stop offset="1" stop-color="#703E2D" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className='d-flex flex-row justify-content-center mt-5' >
                    <div className='d-flex flex-column justify-content-center text-center gap-3'>
                        <h2>Il tuo ordine è stato inviato con successo!</h2>
                        <h6>A breve ti arriverà un mail di conferma ricezione. Un nostro operatore ti contatterà il prima possibile</h6>
                        <Button as={Link} to='/' variant='danger' style={{color:'white'}} className='text-uppercase'>Continua lo shopping</Button>
                    </div>
                </div>



            </Container>

        </>
    )


};